import React, { useState } from 'react'

import InputMask from 'react-input-mask'
import Pagina7 from '../Pagina7'
import { ArrowForwardIcon, ArrowBackIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  useBreakpointValue,
  Heading,
  Stack,
  Flex,
  SimpleGrid,
  RadioGroup,
  Radio,
  Box,
  Switch,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Textarea,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  Avatar,
  Button,
  useToast,
  useColorModeValue
} from '@chakra-ui/react'
import { useForm } from '../../contexts/FormContext'
import { ToastContainer, toast } from 'react-toastify';
import { api_multipague_conta } from '../../services/api';
import { useTheme } from '@material-ui/core'

export default function Pagina6() {
    var md5 = require('md5');
    const toast = useToast()

    const bg = useColorModeValue('#fff', 'gray.800')
    const topNumer = useColorModeValue('#fff', 'gray.800')
    const iconInput = useColorModeValue('gray.800', '#fff')

    const [abriuConta, setAbriuConta] = useState(false)
    const [enviando, setEnviando] = useState(false)
    const [senha, setSenha] = useState('')
    const [errorSenha, setErrorSenha] = useState(false)
    const [confirmaSenha, setConfirmaSenha] = useState(false)
    const [naoConfirmaSenha, setNaoConfirmaSenha] = useState(false)
    const [confirmaSenhaFinanceira, setConfirmaSenhaFinanceira] = useState(false)
    const [naoConfirmaSenhaFinanceira, setNaoConfirmaSenhaFinanceira] = useState(false)
    const [showSenha, setShowSenha] = useState(false)
    const handleClickSenha = () => setShowSenha(!showSenha)
    const [showConfirmaSenha, setShowConfirmaSenha] = useState(false)
    const handleClickConfirmaSenha = () => setShowConfirmaSenha(!showConfirmaSenha)
    const [showSenhaFinanceira, setShowSenhaFinanceira] = useState(false)
    const handleClickSenhaFinanceira = () => setShowSenhaFinanceira(!showSenhaFinanceira)
    const [showConfirmaSenhaFinanceira, setShowConfirmaSenhaFinanceira] = useState(false)
    const handleClickConfirmaSenhaFinanceira = () => setShowConfirmaSenhaFinanceira(!showConfirmaSenhaFinanceira)

    const { displayButton, setDisplayButton } = useForm(false)
    const { possuiConta, setPossuiConta } = useForm()
    const { checkTipoPessoa, setCheckTipoPessoa } = useForm();
    const { contaSolidaria, setContaSolidaria } = useForm()
    const { page1Cnpj, setPage1Cnpj } = useForm()
    const { page1RazaoSocial, setPage1RazaoSocial } = useForm()
    const { page1NomeFantasia, setPage1NomeFantasia } = useForm()
    const { page1InscricaoEstadual, setPage1InscricaoEstadual } = useForm()
    const { page1InscricaoMunicipal, setPage1InscricaoMunicipal } = useForm()
    const { page1Abertura, setPage1Abertura } = useForm()
    const { page1PaginaWeb, setPage1PaginaWeb } = useForm()
    const { page1RepresentanteCpf, setPage1RepresentanteCpf } = useForm()
    const { page1RepresentanteRg, setPage1RepresentanteRg } = useForm()
    const { page1RepresentanteNome, setPage1RepresentanteNome } = useForm()
    const { page1Representante2Cpf, setPage1Representante2Cpf } = useForm()
    const { page1Representante2Rg, setPage1Representante2Rg } = useForm()
    const { page1Representante2Nome, setPage1Representante2Nome } = useForm()
    const { page1TipoDePessoa, setPage1TipoDePessoa } = useForm()

    const { page2RepresentanteEmail, setPage2RepresentanteEmail } = useForm()
    const { page2RepresentanteRedeSocial, setPage2RepresentanteRedeSocial } = useForm()
    const { page2RepresentanteDDD, setPage2RepresentanteDDD } = useForm()
    const { page2RepresentanteDDD2, setPage2RepresentanteDDD2 } = useForm()
    const { page2RepresentanteDDD3, setPage2RepresentanteDDD3 } = useForm()
    const { page2Representante2DDD, setPage2Representante2DDD } = useForm()
    const { page2Representante2DDD2, setPage2Representante2DDD2 } = useForm()
    const { page2Representante2DDD3, setPage2Representante2DDD3 } = useForm()
    const { page2RepresentanteTelefone, setPage2RepresentanteTelefone } = useForm()
    const { page2RepresentanteTelefone2, setPage2RepresentanteTelefone2 } = useForm()
    const { page2RepresentanteTelefone3, setPage2RepresentanteTelefone3 } = useForm()
    const { page2Representante2Email, setPage2Representante2Email } = useForm()
    const { page2Representante2RedeSocial, setPage2Representante2RedeSocial } = useForm()
    const { page2Representante2Telefone, setPage2Representante2Telefone } = useForm()
    const { page2Representante2Telefone2, setPage2Representante2Telefone2 } = useForm()
    const { page2Representante2Telefone3, setPage2Representante2Telefone3 } = useForm()

    const {page3Cep, setPage3Cep} = useForm()
    const {page3Bairro, setPage3Bairro} = useForm()
    const {page3TipoLogradouro, setPage3TipoLogradouro} = useForm()
    const {page3Complemento, setPage3Complemento} = useForm()
    const {page3Numero, setPage3Numero} = useForm()
    const {page3Cidade, setPage3Cidade} = useForm()
    const {page3Uf, setPage3Uf} = useForm()
    const {page3Pais, setPage3Pais} = useForm()
    const {page3TipoResidencia, setPage3TipoResidencia} = useForm()
    const {page3TipoEndereco, setPage3TipoEndereco} = useForm()

    const { pageFive, setPageFive } = useForm()
    const { pageSix, setPageSix } = useForm()
    const { newCorrentista, setNewCorrentista } = useForm()
    const { page6Senha, setPage6Senha } = useForm()
    const { page6ConfirmaSenha, setPage6ConfirmaSenha } = useForm()
    const { page6SenhaFinanceira, setPage6SenhaFinanceira } = useForm()
    const { page6ConfirmaSenhaFinanceira, setPage6ConfirmaSenhaFinanceira } = useForm()

    const senhaAcesso = (e) => {
        const newValue = e.target.value;

        if (
        newValue.match(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/,
        )
        ) {
        setErrorSenha(false);
        setSenha(e.target.value)
        // setDisableButton(false);
        } else {
        setErrorSenha(true);
        // setDisableButton(true);
        }

    }

    const confirmSenha = (e) => {
        const confirmando = e.target.value
        console.log(confirmando)
        if (senha === confirmando) {
            setConfirmaSenha(true)
            setNaoConfirmaSenha(false)
            console.log("CONFIRMOU")
            setDisplayButton(true)
        } else {
            setConfirmaSenha(false)
            setNaoConfirmaSenha(true)
            console.log("NÃO CONFIRMOU")
        }
    }
    const confirmSenhaFinanceira = (e) => {
        const confirmandoFin = e.target.value
        console.log(confirmandoFin)
        if (page6SenhaFinanceira === confirmandoFin) {
            setConfirmaSenhaFinanceira(true)
            setNaoConfirmaSenhaFinanceira(false)
            setDisplayButton(true)
        } else {
            setConfirmaSenhaFinanceira(false)
            setNaoConfirmaSenhaFinanceira(true)
        }
    }

    function isNewCorrentista() {
        setNewCorrentista(true)
    }

    function pageSixToPageFive() {
        setPageFive(true)
        setPageSix(false)
    }

    const formData = {
        pessoaJuridica: {
            cnpj: page1Cnpj,
            razaoSocial: page1RazaoSocial,
            nomeFantasia: page1NomeFantasia,
            inscricaoEstadual: page1InscricaoEstadual,
            inscricaoMunicipal: page1InscricaoMunicipal,
            abertura: page1Abertura != null ? new Intl.DateTimeFormat('pt-BR').format(new Date(page1Abertura)) : new Intl.DateTimeFormat('pt-BR').format(new Date()),
            paginaWeb: page1PaginaWeb,
          },
          correntistasResponsavel: [
            {
              cpf: page1RepresentanteCpf,
              rg: page1RepresentanteRg,
              nome: page1RepresentanteNome,
              representanteLegal: true,
              contato: {
                nome: page1RepresentanteNome,
                email: page2RepresentanteEmail,
                redeSocial: page2RepresentanteRedeSocial === "" ? "Nenhuma" : page2RepresentanteRedeSocial,
                telefones: [
                  {
                    dd1: "",
                    ddd: page2RepresentanteDDD,
                    telefone: page2RepresentanteTelefone,
                  },
                  {
                    dd1: '',
                    ddd: page2RepresentanteDDD2,
                    telefone: page2RepresentanteTelefone2,
                  },
                  {
                    dd1: '',
                    ddd: page2RepresentanteDDD3,
                    telefone: page2RepresentanteTelefone3,
                  },
                ],
              }
                }
          ],
          endereco: {
            cep: page3Cep,
            endereco: page3TipoLogradouro,
            enderecoNum: page3Numero,
            complemento: page3Complemento,
            bairro: page3Bairro,
            cidade: page3Cidade,
            estado: page3Uf,
            pais: page3Pais,
            tipoEndereco: page3TipoEndereco,
            tipoResidencia: page3TipoResidencia,
          },
          tipoPessoa: page1TipoDePessoa,
          aceiteTermo: 1,
          // senha: possuiConta ? null : page6Senha,
          // senhaAssinaturaParceiro: possuiConta ? null : md5(page6SenhaFinanceira),
          nrBanco: 274,
          agenciaPadrao: 1,
          tipoConta: 3,
          descricao: "CONTA PAGAMENTO",
          assinaturaSolidaria: contaSolidaria,
        }
    const formDataDupla = {
        pessoaJuridica: {
            cnpj: page1Cnpj,
            razaoSocial: page1RazaoSocial,
            nomeFantasia: page1NomeFantasia,
            inscricaoEstadual: page1InscricaoEstadual,
            inscricaoMunicipal: page1InscricaoMunicipal,
            abertura: page1Abertura != null ? new Intl.DateTimeFormat('pt-BR').format(new Date(page1Abertura)) : new Intl.DateTimeFormat('pt-BR').format(new Date()),
            paginaWeb: page1PaginaWeb,
          },
          correntistasResponsavel: [
            {
                    cpf: page1RepresentanteCpf,
                    rg: page1RepresentanteRg,
                    nome: page1RepresentanteNome,
                    representanteLegal: newCorrentista ? true : false,
                    contato: {
                      nome: page1RepresentanteNome,
                      email: page2RepresentanteEmail,
                      redeSocial: page2RepresentanteRedeSocial === "" ? "Nenhuma" : page2RepresentanteRedeSocial,
                      telefones: [
                        {
                          dd1: "",
                          ddd: page2RepresentanteDDD,
                          telefone: page2RepresentanteTelefone,
                        },
                        {
                          dd1: '',
                          ddd: page2RepresentanteDDD2,
                          telefone: page2RepresentanteTelefone2,
                        },
                        {
                          dd1: '',
                          ddd: page2RepresentanteDDD3,
                          telefone: page2RepresentanteTelefone3,
                        },
                      ]
                    }
                },
                {
                    cpf: page1Representante2Cpf,
                    rg: page1Representante2Rg,
                    nome: page1Representante2Nome,
                    representanteLegal: contaSolidaria ? true : false,
                    contato: {
                      nome: page1Representante2Nome,
                      email: page2Representante2Email,
                      redeSocial: page2Representante2RedeSocial === "" ? "Nenhuma" : page2Representante2RedeSocial,
                      telefones: [
                        {
                          dd1: '',
                          ddd: page2Representante2DDD,
                          telefone: page2Representante2Telefone,
                        },
                        {
                          dd1: '',
                          ddd: page2Representante2DDD2,
                          telefone: page2Representante2Telefone2,
                        },
                        {
                          dd1: '',
                          ddd: page2Representante2DDD3,
                          telefone: page2Representante2Telefone3,
                        },
                      ],
                    },
                  },
          ],
          endereco: {
            cep: page3Cep,
            endereco: page3TipoLogradouro,
            enderecoNum: page3Numero,
            complemento: page3Complemento,
            bairro: page3Bairro,
            cidade: page3Cidade,
            estado: page3Uf,
            pais: page3Pais,
            tipoEndereco: page3TipoEndereco,
            tipoResidencia: page3TipoResidencia,
          },
          tipoPessoa: page1TipoDePessoa,
          aceiteTermo: 1,
          // senha: possuiConta ? null : page6Senha,
          // senhaAssinaturaParceiro: possuiConta ? null : md5(page6SenhaFinanceira),
          nrBanco: 274,
          agenciaPadrao: 1,
          tipoConta: 3,
          descricao: "CONTA PAGAMENTO",
          assinaturaSolidaria: contaSolidaria,
        }

    const formDataF = {
        pessoaFisica: {
            nome: page1RepresentanteNome,
            rg: page1RepresentanteRg,
            cpf: page1RepresentanteCpf
          },
          correntistasResponsavel: [
            {
              cpf: page1RepresentanteCpf,
              rg: page1RepresentanteRg,
              nome: page1RepresentanteNome,
              representanteLegal: true,
              contato: {
                nome: page1RepresentanteNome,
                email: page2RepresentanteEmail,
                redeSocial: page2RepresentanteRedeSocial === "" ? "Nenhuma" : page2RepresentanteRedeSocial,
                telefones: [
                  {
                    dd1: "",
                    ddd: page2RepresentanteDDD,
                    telefone: page2RepresentanteTelefone,
                  },
                  {
                    dd1: '',
                    ddd: page2RepresentanteDDD2,
                    telefone: page2RepresentanteTelefone2,
                  },
                  {
                    dd1: '',
                    ddd: page2RepresentanteDDD3,
                    telefone: page2RepresentanteTelefone3,
                  },
                ],
              },
            }
          ],
          endereco: {
            cep: page3Cep,
            endereco: page3TipoLogradouro,
            enderecoNum: page3Numero,
            complemento: page3Complemento,
            bairro: page3Bairro,
            cidade: page3Cidade,
            estado: page3Uf,
            pais: page3Pais,
            tipoEndereco: page3TipoEndereco,
            tipoResidencia: page3TipoResidencia,
          },
          tipoPessoa: page1TipoDePessoa,
          aceiteTermo: 1,
          // senha: possuiConta ? null : page6Senha,
          // senhaAssinaturaParceiro: possuiConta ? null : md5(page6SenhaFinanceira),
          nrBanco: 274,
          agenciaPadrao: 1,
          tipoConta: 3,
          descricao: "CONTA PAGAMENTO",
          assinaturaSolidaria: contaSolidaria,
        }
    const formDataFDupla = {
        pessoaFisica: {
            nome: page1RepresentanteNome,
            rg: page1RepresentanteRg,
            cpf: page1RepresentanteCpf
          },
          correntistasResponsavel: [
            {
                    cpf: page1RepresentanteCpf,
                    rg: page1RepresentanteRg,
                    nome: page1RepresentanteNome,
                    representanteLegal: newCorrentista ? true : false,
                    contato: {
                      nome: page1RepresentanteNome,
                      email: page2RepresentanteEmail,
                      redeSocial: page2RepresentanteRedeSocial === "" ? "Nenhuma" : page2RepresentanteRedeSocial,
                      telefones: [
                        {
                          dd1: "",
                          ddd: page2RepresentanteDDD,
                          telefone: page2RepresentanteTelefone,
                        },
                        {
                          dd1: '',
                          ddd: page2RepresentanteDDD2,
                          telefone: page2RepresentanteTelefone2,
                        },
                        {
                          dd1: '',
                          ddd: page2RepresentanteDDD3,
                          telefone: page2RepresentanteTelefone3,
                        },
                      ]
                    }
                },
                {
                    cpf: page1Representante2Cpf,
                    rg: page1Representante2Rg,
                    nome: page1Representante2Nome,
                    representanteLegal: contaSolidaria ? true : false,
                    contato: {
                      nome: page1Representante2Nome,
                      email: page2Representante2Email,
                      redeSocial: page2Representante2RedeSocial === "" ? "Nenhuma" : page2Representante2RedeSocial,
                      telefones: [
                        {
                          dd1: '',
                          ddd: page2Representante2DDD,
                          telefone: page2Representante2Telefone,
                        },
                        {
                          dd1: '',
                          ddd: page2Representante2DDD2,
                          telefone: page2Representante2Telefone2,
                        },
                        {
                          dd1: '',
                          ddd: page2Representante2DDD3,
                          telefone: page2Representante2Telefone3,
                        },
                      ],
                    },
                  },
          ],
          endereco: {
            cep: page3Cep,
            emdereco: page3TipoLogradouro,
            enderecoNum: page3Numero,
            complemento: page3Complemento,
            bairro: page3Bairro,
            cidade: page3Cidade,
            estado: page3Uf,
            pais: page3Pais,
            tipoEndereco: page3TipoEndereco,
            tipoResidencia: page3TipoResidencia,
          },
          tipoPessoa: page1TipoDePessoa,
          aceiteTermo: 1,
          // senha: possuiConta ? null : page6Senha,
          // senhaAssinaturaParceiro: possuiConta ? null : md5(page6SenhaFinanceira),
          nrBanco: 274,
          agenciaPadrao: 1,
          tipoConta: 3,
          descricao: "CONTA PAGAMENTO",
          assinaturaSolidaria: contaSolidaria,
        }

    function finalizar() {
      setEnviando(true);
      api_multipague_conta
      .post(`/aberturaConta`, newCorrentista && page1TipoDePessoa == "J" ? JSON.stringify(formDataDupla) : !newCorrentista && page1TipoDePessoa == "J" ? JSON.stringify(formData) : newCorrentista && page1TipoDePessoa == "F" ? JSON.stringify(formDataFDupla) :  JSON.stringify(formDataF), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(function (response) {
        setEnviando(false);
        // setCarregando(false);
        console.log(response);
        toast({
            title: 'Conta criada!',
            description: `Bem-vindo ao ${useTheme()?.config?.clienteName}`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        setAbriuConta(true)
      })
      .catch(function (error) {
        console.log(error);
        setEnviando(false);
        // setCarregando(false);
        if (
          error.response.status == 400 ||
          error.response.status == 404 ||
          error.response.status == 409 ||
          error.response.status == 500
        ) {
          error.response.data.listErrors ?
          error.response.data.listErrors.map(usr => {
            return(
              toast({
                title: 'Ops!',
                description: usr,
                status: 'error',
                duration: 3000,
                isClosable: true,
              })
            )
          })
          :
          toast({
                title: 'Ops!',
                description: error.response.data.mensagem,
                status: 'error',
                duration: 3000,
                isClosable: true,
              })

            // toast({
            //     title: 'Ops!',
            //     description: error.response.data.mensagem,
            //     status: 'error',
            //     duration: 3000,
            //     isClosable: true,
            //   })
        }
      });
    }

    const isWideVersion = useBreakpointValue({
        base: false,
        lg: true
      })

    return (
        <>
        {abriuConta && (
            <Pagina7 />
        )}
        {isWideVersion && !abriuConta && (
          <>
            <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" justifyContent="space-between" >
              <Box display="flex">
                <Stack direction='row'>
                  <Avatar name='1' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Heading as='h4' size='sm' my="2" ml="2">
                  {checkTipoPessoa ? 'Dados da pessoa jurídica' : 'Dados da pessoa física'}
                </Heading>
              </Box>

              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='2' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Dados de contato
                </Text>
              </Box>

              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='3' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Dados de endereço
                </Text>
              </Box>

              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='4' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Minha conta
                </Text>
              </Box>

              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='5' size='sm' bg='gray.300' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Termo
                </Text>
              </Box>

              <Box display="flex">
                <Stack direction='row' ml={5}>
                  <Avatar name='6' size='sm' bg='#65c4d5' color={topNumer} />
                </Stack>
                <Text fontSize='sm' my="2" ml="2">
                    Senha
                </Text>
              </Box>

            </Flex>


            <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" justifyContent="space-between" >
              <Box alignItems='center'>
                <Heading as='h4' size='md' mb="0">
                    Confira os emails que receberão as instruções de cadastro de senhas.
                </Heading>
              </Box>
            </Flex>
            <Flex direction="column">

                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6" border='1px' borderColor='gray.200' bg={bg} borderRadius={8}>

                    <FormControl>

                        <SimpleGrid columns={isWideVersion ? 2 : 1} spacing={2} bg={bg}>
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                  <FormLabel htmlFor='primeirousuario'>Usuário</FormLabel>
                                    <Input
                                        id='primeirousuario'
                                        type="email"
                                        borderColor='gray.300'
                                        value={page2RepresentanteEmail}
                                        disabled
                                    />
                              </Box>
                              {newCorrentista &&
                              <Box
                                  p={["3","3"]}
                                  borderRadius={8}
                                  pb="4"
                              >
                                  <FormLabel htmlFor='segundousuario'>Segundo usuário</FormLabel>
                                    <Input
                                        id='segundousuario'
                                        type="email"
                                        borderColor='gray.300'
                                        value={page2Representante2Email}
                                        disabled
                                    />
                              </Box>
                              }
                        </SimpleGrid>
                    </FormControl>
                </Flex>
                <Flex w="100%" my="6" maxWidth={1120} mx="auto" px="6"  justifyContent="flex-end">
                    <Box>
                        <Button leftIcon={<ArrowBackIcon />} mr="5" colorScheme='#394C59' variant='outline' onClick={() => pageSixToPageFive()}>
                        Anterior
                        </Button>
                        <Button
                            rightIcon={<ArrowForwardIcon />}
                            colorScheme='teal'
                            isLoading={enviando}
                            loadingText='Enviando'
                            onClick={() => finalizar()}
                        >
                        Finalizar
                        </Button>
                    </Box>
                </Flex>
            </Flex>
            </>
            )}
     </>
    )
}
