import React, { useEffect } from 'react';
import { useForm } from '../contexts/FormContext';
import {
  Heading,
  Flex,
  Box,
  useColorMode,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';

import Pagina1 from '../components/Pagina1';
import Pagina2 from '../components/Pagina2';
import Pagina3 from '../components/Pagina3';
import Pagina4 from '../components/Pagina4';
import Pagina5 from '../components/Pagina5';
import Pagina6 from '../components/Pagina6';
import { useTheme } from '@material-ui/core';

export default function NewIndex() {
  const { pageOne, setPageOne } = useForm();
  const { pageTwo, setPageTwo } = useForm();
  const { pageThree, setPageThree } = useForm();
  const { pageFour, setPageFour } = useForm();
  const { pageFive, setPageFive } = useForm();
  const { pageSix, setPageSix } = useForm();

  useEffect(() => {
    console.log('Deploy register');
  }, []);

  const { colorMode, toggleColorMode } = useColorMode();

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <>
      <Flex w="100%">
        <Box
          bg="#394C59"
          w="100%"
          height={50}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={isWideVersion ? '6' : '0'}
          color="gray.50"
        >
          <Heading as="h4" size="md" my="3" ml="2">
          {useTheme()?.config?.clienteName} - Abertura de Conta
          </Heading>
          {colorMode === 'light' ? (
            <MoonIcon
              size="lg"
              cursor="pointer"
              mr="2"
              onClick={toggleColorMode}
            >
              Toggle {colorMode === 'light' ? 'Dark' : 'Light'}
            </MoonIcon>
          ) : (
            <SunIcon
              size="lg"
              cursor="pointer"
              mr="2"
              onClick={toggleColorMode}
            >
              Toggle {colorMode === 'light' ? 'Dark' : 'Light'}
            </SunIcon>
          )}
        </Box>
      </Flex>
      {pageOne && <Pagina1 />}
      {pageTwo && <Pagina2 />}
      {pageThree && <Pagina3 />}
      {pageFour && <Pagina4 />}
      {pageFive && <Pagina5 />}
      {pageSix && <Pagina6 />}
    </>
  );
}
