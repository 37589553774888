/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from './routes/index';
import { FormProvider } from "./contexts/FormContext"

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { theme } from './styles/theme';
export default function Index() {
  return (
    <>
    <ColorModeScript initialColorMode={theme.initialColorMode} />
    <FormProvider>
      <ChakraProvider theme={theme}>
          <Routes />
      </ChakraProvider>
    </FormProvider>
    </>
  )
}
